import api from '@/api/index'

export default {
  getAll: async payload => {
    const response = await api.invoice.getAll(payload)
    const { data } = response
    return data
  },
  set: async payload => {
    const response = await api.invoice.set(payload)
    return response
  },
  get: async payload => {
    const response = await api.invoice.get(payload)
    const { data } = response
    return data
  },
  printInvoice: async payload => {
    const response = await api.invoice.printInvoice(payload)
    return response
  },
  cancelInvoice: async payload => {
    const response = await api.invoice.cancelInvoice(payload)
    return response
  },
  sendEmail: async payload => {
    const response = await api.invoice.sendInvoice(payload)
    return response
  },
}
